<template>
    <div>
        <div class="check-all-jadwal">
            <input
                type="checkbox"
                name=""
                id=""
                v-model="allSelected"
                @click="allChecked"
            />
            Pilih Semua
        </div>
        <div class="check-jadwal-container">
            <div
                v-for="(jadwal, index) in dataJadwals"
                :key="index"
                class="check-jadwal"
            >
                <input
                    type="checkbox"
                    name=""
                    id=""
                    v-model="dataJadwals[index].isSelected"
                />
                {{ jadwal.text }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MultipleJadwalCheck",
    props: {
        jadwals: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            dataJadwals: [],
            allSelected: false
        };
    },
    watch: {
        allSelected(newVal) {
            // if (newVal) {
            //     this.dataJadwals.forEach(jadwal => {
            //         jadwal.isSelected = true;
            //     });
            // } else {
            //     this.dataJadwals.forEach(jadwal => {
            //         jadwal.isSelected = false;
            //     });
            // }
        },
        dataJadwals: {
            handler(newVal) {
                if (newVal.length > 0) {
                    let isAllSelected = true
                    newVal.forEach(jadwal => {
                        if(!jadwal.isSelected){
                            isAllSelected = false
                        }
                    })
                    if(isAllSelected){
                         this.allSelected = true
                    }else{
                        this.allSelected = false;
                    }
                    this.$emit("update:dataJadwals", newVal);
                }
            },
            deep: true
        },
        jadwals: {
            handler(val) {
                this.dataJadwals = val;
            }
        }
    },
    methods: {
        allChecked() {
            this.allSelected = !this.allSelected;

            this.dataJadwals.forEach(jadwal => {
                jadwal.isSelected = this.allSelected;
            });
        }
    },
    created() {
        // jadwals.forEach(el => {
        //     this.dataJadwals[el.id] = false;
        // });
        this.dataJadwals = this.jadwals;
    }
};
</script>

<style></style>
