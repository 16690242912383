<template>
    <Modal class="custom-modal doctor-filter">
        <ModalHeader class="custom-header"
            ><h3>{{ modalTitle }}</h3>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click.prevent="dismiss(idComponent)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </ModalHeader>
        <ModalBody>
            <form class="form-modal">
                <div class="form-group">
                    <label class=" col-form-label form-label"
                        >Dokter<span style="color:red;">*</span></label
                    >
                    <div v-if="!isDoctorDetail">
                        <multiselect
                            class="holiday-multiselect"
                            track-by="id"
                            label="nama"
                            :allow-empty="true"
                            :multiple="false"
                            :close-on-select="true"
                            v-model="ketidakhadiranData.selectedDoctor"
                            :options="doctorOptions"
                            placeholder="Cari Dokter"
                            :searchable="true"
                            :loading="isDoctorSelectLoading"
                            v-validate="'required'"
                            data-vv-as="dokter"
                            name="dokter"
                            :class="{
                                'input-invalid': errorsInput.selectedDoctor
                            }"
                            @search-change="searchDoctor"
                        >
                        </multiselect>

                        <div
                            v-show="errorsInput.selectedDoctor"
                            class="invalid-error"
                        >
                            Dokter harus diisi
                        </div>
                    </div>
                    <div v-else>
                        <p class="image-name">
                            {{ ketidakhadiranData.selectedDoctor.nama }}
                        </p>
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label"
                        >Periode <span style="color:red;">*</span>
                    </label>
                    <div :id="dateRangeContainerId">
                        <!-- <DatePicker
                            class="custom-datepicker"
                            :changedValue.sync="ketidakhadiranData.tanggal"
                            :id-date-picker="
                                'jadwal_date_start_' + 'ketidakhadiran'
                            "
                            :picker-value="ketidakhadiranData.tanggal"
                            place-holder="Pilih Tanggal Ketidakhadiran"
                            :is-validate="true"
                            start-date="0d"
                            end-date=""
                        >
                        </DatePicker> -->
                        <DateRangePicker
                            :changedValueStart.sync="
                                ketidakhadiranData.tanggal.start
                            "
                            id-date-picker="absence_dateRange"
                            :changedValueEnd.sync="
                                ketidakhadiranData.tanggal.end
                            "
                            :periodeAwal="''"
                            :periodeAkhir="''"
                            class="custom-datepicker"
                            place-holder="Pilih Tanggal Ketidakhadiran"
                            picker-value
                            :class="{
                                'input-invalid': errorsInput.tanggal
                            }"
                            :minDate="todayDate()"
                            :dateRangeContainerId="dateRangeContainerId"
                        >
                        </DateRangePicker>
                        <div v-show="errorsInput.tanggal" class="invalid-error">
                            Tanggal harus diisi
                        </div>
                    </div>
                </div>
                <!-- <div v-if="isJadwalLoading">
                    Loading...
                </div> -->
                <div class="form-group ">
                    <label class="col-form-label form-label"
                        >Jadwal<span style="color:red;">*</span></label
                    >
                    <!-- <div class="skeleton-pxjnfhnooqk">  showErrorGetJadwal-->
                    <div v-if="showErrorGetJadwal" class="get-jadwal__error">
                        <div style="display:flex;">
                            <img
                                :src="dangerImg"
                                alt=""
                                style="margin-right: 6px;"
                            />
                            <p>Terjadi kegagalan dalam menampilkan data</p>
                        </div>
                        <a @click.prevent="refetch">Muat Ulang</a>
                    </div>
                    <div v-if="!isJadwalLoading">
                        <div
                            class="jadwal-container"
                            :class="{
                                'input-invalid': errorsInput.jadwal
                            }"
                            v-if="jadwalsOptions.length > 0"
                        >
                            <MultipleJadwalCheck
                                :dataJadwals.sync="ketidakhadiranData.jadwal"
                                :jadwals="jadwalsOptions"
                            />
                        </div>

                        <div v-else>
                            <p
                                v-if="
                                    !ketidakhadiranData.tanggal.start ||
                                        !ketidakhadiranData.selectedDoctor
                                "
                            >
                                Silakan pilih dokter dan periode terlebih dahulu
                            </p>
                            <div v-else class="get-jadwal__error">
                                <div style="display:flex;">
                                    <img
                                        :src="dangerImg"
                                        alt=""
                                        style="margin-right: 6px;"
                                    />
                                    <p>
                                        Jadwal tidak ditemukan. Silakan ubah
                                        dokter dan/atau periode
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div v-show="errorsInput.jadwal" class="invalid-error">
                            Jadwal harus diisi
                        </div>
                    </div>
                    <!-- <div class="jadwal-container" v-else>
                        <div class="skeleton-pxjnfhnooqk"></div>
                    </div> -->
                    <div v-else class="jadwal-container">
                        <div class="skeleton skeleton-top"></div>
                        <div class="skeleton skeleton-checkbox"></div>
                        <div class="skeleton skeleton-checkbox"></div>
                        <div class="skeleton skeleton-checkbox"></div>
                        <div class="skeleton skeleton-checkbox"></div>
                        <div class="skeleton skeleton-checkbox"></div>
                    </div>
                    <!-- </div> -->
                </div>
                <div class="form-divider"></div>
                <div class="form-group">
                    <label class="col-form-label form-label"
                        >Alasan Ketidakhadiran (opsional, tidak muncul di mobile
                        app)</label
                    >
                    <div>
                        <!-- <VueInputText
                            :placeholder="'Tulis Alasan Ketidakhadiran'"
                            :validationName="'Alasan Ketidakhadiran'"
                            :scope="'nama-tarif'"
                            v-model="ketidakhadiranData.alasan"
                        >
                        </VueInputText> -->
                        <textarea
                            placeholder="Tulis alasan yang ditujukan kepada pasien (opsional, tidak muncul di mobile app)"
                            style="width: 100%; height: 90px;"
                            v-model="ketidakhadiranData.alasan"
                            name=""
                            id=""
                        ></textarea>
                    </div>
                </div>
                <div class="form-divider"></div>
            </form>
        </ModalBody>

        <ModalFooter class="custom-footer">
            <div class="d-flex">
                <a
                    class="link-batal"
                    style="margin-right: 40px;line-height: 40px;"
                    @click.prevent="dismiss(idComponent)"
                    >Batal</a
                >
                <!-- :disabled="isEmpty()" -->
                <button
                    @click.prevent="saveData"
                    class="btn btn-primary custom-modal-button"
                >
                    Simpan
                </button>
            </div>
        </ModalFooter>
    </Modal>
</template>

<script>
// import PlaceHolderImage from "../../assets/placeholder.png";

import Modal from "../../../_modal/Modal.vue";
import ModalHeader from "../../../_modal/ModalHeader.vue";
import ModalBody from "../../../_modal/ModalBody.vue";
import ModalFooter from "../../../_modal/ModalFooter.vue";
import ModalMixin from "../../../../mixins/ModalMixins";

import VueInputText from "../../../_general/VueInputText.vue";
import DatePicker from "../../../_general/DatePicker.vue";
import DateRangePicker from "../../../_general/CustomDateRangePicker.vue";
import MultipleJadwalCheck from "../MultipleJadwalCheck.vue";
// import EyeIconSlashed from "../../../_icons/EyeIconSlashed.vue";
// import EyeFillIcon from "../../../_icons/EyeFillIcon.vue";

// import FileInput from "../../../_base/FileInputCustom.vue";
import Multiselect from "vue-multiselect";
import VeeValidate from "vee-validate";
import MaxMinDate from "@/constants/date-range-enum"
import DangerImg from "../../../_icons/DangerImg.png";
VeeValidate.setMode("passive");
export default {
    name: "KlinikAddDataModal",
    components: {
        Modal,
        ModalHeader,
        ModalBody,
        ModalFooter,
        MultipleJadwalCheck,
        Multiselect,
        DatePicker,
        DateRangePicker,
        // FileInput,
        // EyeIconSlashed,
        // EyeFillIcon,
        VueInputText
    },
    mixins: [ModalMixin],
    provide() {
        return {
            $validator: this.$validator
        };
    },
    data() {
        var vx = this;
        return {
            dateRangeContainerId: `date-range-container`,
            isDoctorSelectLoading: false,
            doctorOptions: [],
            searchDoctor: _.debounce(async query => {
                try {
                    if (query) {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchDoctor ==
                                "function"
                            ) {
                                vx.isDoctorSelectLoading = true;
                                const resp = await vx.componentData.searchDoctor(
                                    query
                                );
                                vx.isDoctorSelectLoading = false;
                                vx.doctorOptions = resp;
                            }
                        }
                    } else {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchDoctor ==
                                "function"
                            ) {
                                vx.isDoctorSelectLoading = true;
                                const resp = await vx.componentData.searchDoctor();
                                vx.isDoctorSelectLoading = false;
                                vx.doctorOptions = resp;
                            }
                        }
                    }
                } catch (error) {
                    vx.isDoctorSelectLoading = false;
                    console.log(error);
                }
            }, 500),
            // jadwalOptions: [
            //     { nama: "Sinatra", language: "Ruby" },
            //     { nama: "Laravel", language: "PHP" },
            //     { nama: "Phoenix", language: "Elixir" }
            // ],
            ketidakhadiranData: {
                alasan: null,
                jadwal: [],
                selectedDoctor: null,
                tanggal: {
                    start: null,
                    end: null
                }
            },
            daysByDates: [],
            jadwalsOptions: [],
            daysValue: {
                MONDAY: 1,
                TUESDAY: 2,
                WEDNESDAY: 3,
                THURSDAY: 4,
                FRIDAY: 5,
                SATURDAY: 6,
                SUNDAY: 7
            },
            isJadwalLoading: false,
            dates: [],
            showErrorGetJadwal: false,
            dangerImg: DangerImg,
            errorsInput: {
                selectedDoctor: false,
                jadwal: false,
                tanggal: false
            },
            jadwalFirstLoad: false,
            isDoctorDetail: false,
            jadwalDate: []
        };
    },
    watch: {
        "ketidakhadiranData.tanggal": {
            handler(newVal, oldVal) {
                const dates = this.enumerateDaysBetweenDates(
                    newVal.start,
                    newVal.end
                );
                this.dates = this.daysAndDates(dates).sort((a, b) => {
                    return this.daysValue[a.day] - this.daysValue[b.day];
                });
                // console.log(_.uniq(this.daysFromDates(dates)), "days");
                this.daysByDates = this.daysFromDates(dates);
                if (newVal.start && newVal.end) {
                    this.errorsInput.tanggal = false;
                    if (
                        this.ketidakhadiranData.selectedDoctor &&
                        !this.jadwalDate.length
                    ) {
                        this.getJadwals(
                            this.ketidakhadiranData.selectedDoctor.id
                        );
                    } else if (
                        this.ketidakhadiranData.selectedDoctor &&
                        this.jadwalDate.length
                    ) {
                        this.isJadwalLoading = true;
                        this.jadwalsOptions = this.makeJadwalData();
                        this.isJadwalLoading = false;
                    }
                } else {
                    this.errorsInput.tanggal = true;
                    this.jadwalFirstLoad = false;
                    this.jadwalsOptions = [];
                }
            },
            deep: true
        },
        "ketidakhadiranData.selectedDoctor": {
            handler(newVal, oldVal) {
                if (newVal) {
                    this.errorsInput.selectedDoctor = false;
                    if (
                        this.ketidakhadiranData.tanggal.start &&
                        this.ketidakhadiranData.tanggal.end
                    ) {
                        this.getJadwals(newVal.id);
                    }
                } else {
                    this.errorsInput.selectedDoctor = true;
                    this.jadwalFirstLoad = false;
                    this.jadwalsOptions = [];
                }
            },
            deep: true
        },
        "ketidakhadiranData.jadwal": {
            handler(newVal, oldVal) {
                if (newVal.length > 0) {
                    // this.errorsInput.jadwal = false;
                    let isError = true;
                    newVal.forEach(jadwal => {
                        if (jadwal.isSelected) {
                            isError = false;
                        }
                    });
                    if (this.jadwalFirstLoad) {
                        this.errorsInput.jadwal = isError;
                    } else {
                        this.jadwalFirstLoad = true;
                    }
                } else {
                    this.errorsInput.jadwal = true;
                }
            },
            deep: true
        }
    },
    props: {
        modalTitle: {
            type: String,
            default: "Tambah Ketidakhadiran"
        }
    },
    methods: {
        todayDate() {
            return moment().format("DD/MM/YYYY");
        },
        isEmpty() {
            const isEmpty = _.values(this.ketidakhadiranData).every(_.isEmpty);
            return isEmpty;
        },
        checkMandatory() {
            let isError = false;
            if (
                !this.ketidakhadiranData.tanggal.start ||
                !this.ketidakhadiranData.tanggal.end
            ) {
                this.errorsInput.tanggal = true;
                isError = true;
            } else {
                this.errorsInput.tanggal = false;
            }
            if (!this.ketidakhadiranData.selectedDoctor) {
                this.errorsInput.selectedDoctor = true;
                isError = true;
            } else {
                this.errorsInput.selectedDoctor = false;
            }
            if (this.ketidakhadiranData.jadwal.length > 0) {
                let isFilledAll = false;
                this.ketidakhadiranData.jadwal.forEach(jadwal => {
                    if (jadwal.isSelected) {
                        isFilledAll = true;
                    }
                });
                if (!isFilledAll) {
                    this.errorsInput.jadwal = true;
                    isError = true;
                }
            } else {
                this.errorsInput.jadwal = false;
            }
            return isError;
        },
        filterJadwal(jadwal) {
            return jadwal.filter(item => {
                return item.isSelected;
            });
        },
        saveData() {
            const isError = this.checkMandatory();
            if (!isError) {
                if (this.componentData) {
                    if (typeof this.componentData.action == "function") {
                        this.componentData.action({
                            alasan: this.ketidakhadiranData.alasan,
                            jadwal: this.filterJadwal(
                                this.ketidakhadiranData.jadwal
                            ),
                            nama: this.ketidakhadiranData.selectedDoctor.nama,
                            id: this.ketidakhadiranData.selectedDoctor.id
                        });
                    }
                }
            }
        },
        enumerateDaysBetweenDates(startDate, endDate) {
            let dates = [];

            let currDate = moment(startDate).startOf("day");
            let lastDate = moment(endDate).startOf("day");
            if (startDate !== endDate) {
                dates.push(moment(startDate).toDate());
            }
            while (currDate.add(1, "days").diff(lastDate) < 0) {
                dates.push(currDate.clone().toDate());
            }
            dates.push(moment(endDate).toDate());
            return dates;
        },
        daysFromDates(dates) {
            return dates.map(date => moment(date).format("dddd"));
        },
        daysAndDates(dates) {
            return dates.map(date => {
                return {
                    day: moment(date).format("dddd"),
                    date: moment(date).format("YYYY-MM-DD"),
                    isSelected: false
                };
            });
        },
        hariToDay(hari) {
            const hariIndo = {
                senin: "MONDAY",
                selasa: "TUESDAY",
                rabu: "WEDNESDAY",
                kamis: "THURSDAY",
                jumat: "FRIDAY",
                sabtu: "SATURDAY",
                minggu: "SUNDAY"
            };
            if (hariIndo[hari]) {
                return hariIndo[hari];
            }
        },
        daysToHari(hari) {
            const hariIndo = {
                MONDAY: "Senin",
                TUESDAY: "Selasa",
                WEDNESDAY: "Rabu",
                THURSDAY: "Kamis",
                FRIDAY: "Jumat",
                SATURDAY: "Sabtu",
                SUNDAY: "Minggu"
            };
            if (hariIndo[hari]) {
                return hariIndo[hari];
            }
        },
        makeDaysAndDates(validJadwal) {
            const groupedByDay = _.groupBy(this.dates, date => {
                return date.day;
            });
            validJadwal.forEach(jadwal => {
                if (groupedByDay[jadwal.hari]) {
                    groupedByDay[jadwal.hari].forEach(date => {
                        if (date.day == jadwal.hari) {
                            jadwal.tanggal.push(date.date);
                        }
                    });
                }
            });
            const jadwalData = [];
            validJadwal.forEach(jadwal => {
                jadwal.tanggal.forEach(tanggal => {
                    // console.log('-->', jadwal)
                    const startDate = jadwal.tanggalMulai ? jadwal.tanggalMulai.slice(0, 10) : MaxMinDate.MIN_DATE
                    const endDate = jadwal.tanggalSelesai ? jadwal.tanggalSelesai.slice(0, 10) : MaxMinDate.MAX_DATE
                    if (moment(tanggal).isBetween(startDate, endDate, null,[])) {
                        jadwalData.push({
                            id: jadwal.ID,
                            text: this.jadwalString({
                                hari: jadwal.hari,
                                tanggal: tanggal,
                                jamMulai: jadwal.jamMulai,
                                jamSelesai: jadwal.jamSelesai
                            }),
                            tanggal: new Date(tanggal),
                            isSelected: false,
                            jadwalObject: jadwal
                        });
                    }
                });
            });
            return jadwalData;
        },
        jadwalString({ hari, tanggal, jamMulai, jamSelesai }) {
            let day = hari ? hari : "-";
            let date = tanggal
                ? moment(tanggal, "YYYY-MM-DD").format("DD/MMM/YYYY")
                : "-";
            let start = jamMulai ? jamMulai.slice(0, -3) : "-";
            let end = jamSelesai ? jamSelesai.slice(0, -3) : "-";
            return `${day}, ${date} ( ${start} - ${end} )`;
        },
        makeJadwalData() {
            const startDate = MaxMinDate.MIN_DATE
            const endDate = MaxMinDate.MAX_DATE
            let validJadwal = this.jadwalDate.filter(jadwal => {
                const startJadwalDate = jadwal.tanggalMulai ? jadwal.tanggalMulai.slice(0, 10) : startDate
                const endJadwalDate = jadwal.tanggalSelesai ? jadwal.tanggalSelesai.slice(0, 10) : endDate
                if ((moment(this.ketidakhadiranData.tanggal.start).isBetween(startJadwalDate,endJadwalDate,null,[])) ||
                (moment(this.ketidakhadiranData.tanggal.end).isBetween(startJadwalDate,endJadwalDate,null,[]))
                )
                 return true
                return false
            });


            validJadwal = validJadwal.map(jadwal => {
                return {
                    ...jadwal,
                    tanggal: []
                };
            });
            
            validJadwal.sort((a, b) => {
                return (
                    Number(a.jamMulai.slice(0, 2)) -
                    Number(b.jamMulai.slice(0, 2))
                );
            });
            let jadwalData = this.makeDaysAndDates(validJadwal);
            jadwalData.sort((a, b) => {
                return a.tanggal - b.tanggal;
            });
            jadwalData.sort((a, b) => {
                return this.daysValue[a.hari] - this.daysValue[b.hari];
            });
            return jadwalData;
        },
        async getJadwals(id) {
            if (this.componentData) {
                if (this.componentData.getJadwalByDokterId) {
                    if (
                        typeof this.componentData.getJadwalByDokterId ==
                        "function"
                    ) {
                        try {
                            this.showErrorGetJadwal = false;
                            this.isJadwalLoading = true;
                            const jadwals = await this.componentData.getJadwalByDokterId(
                                id
                            );
                            this.jadwalDate = jadwals;
                            if (Array.isArray(this.jadwalDate)) {
                                this.jadwalsOptions = this.makeJadwalData();
                                this.isJadwalLoading = false;
                            } else {
                                throw new Error("Get Jadwal Error");
                            }
                        } catch (error) {
                            console.log(error);
                            this.showErrorGetJadwal = true;
                        }
                    }
                }
            }
        },
        refetch() {
            if (this.ketidakhadiranData.selectedDoctor) {
                this.getJadwals(this.ketidakhadiranData.selectedDoctor.id);
            }
        }
    },
    created() {
        this.searchDoctor();
        if (this.componentData.isDoctorDetail) {
            this.isDoctorDetail = this.componentData.isDoctorDetail;
        }
        if (this.componentData.selectedDoctor) {
            this.ketidakhadiranData.selectedDoctor = this.componentData.selectedDoctor;
        }
    },
    mounted() {
        // let DatePickers = document.querySelectorAll(".daterangepicker");
        // DatePickers.forEach(el => {
        //     el.style.zIndex = "1055";
        // });
    }
};
</script>

<style></style>
