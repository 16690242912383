<template>
  <div class="kt-form__control">
    <select
      @change="dayChanged()"
      v-model="selected"
      :value="daySelected"
      class="form-control"
      :id="jadwalId"
      :name="jadwalId"
      data-size="5"
    >
      <option
        v-for="(key, value) in dayName"
        :key="value"
        :value="value"
      >{{ key }}</option>
    </select>
  </div>
</template>

<script>
import DayNameEnum from './../../constants/day-name-enum';

export default {
  data() {
    return {
      dayName: DayNameEnum,
      selected: null
    }
  },
  props: {
    jadwalId: {},
    value: { type: String }
  },
  computed: {
    daySelected() {
      this.selected = this.value;
    }
  },
  methods: {
    getSelectPicker: function () {
      $("#" + this.jadwalId).selectpicker({
        title: "Pilih Hari ..."
      });

      this.$nextTick(() => {
        $("#" + this.jadwalId).selectpicker("refresh");
      });
    },
    dayChanged: function () {
      this.$emit("update:changedValue", this.selected);
    }
  },
  mounted() {
    this.getSelectPicker();
  }
}
</script>